<template>
    <v-container class="inscrir" grid-list-xs >
        <!-- <v-row>
            <v-col align="center">
                <h1 class="propsHead compHead" style="color:#2E77A3"  >
                    Inscription
                </h1>
                <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-snackbar  v-model="snackbar" :color="snackColor"  timeout="5000" top>
            {{ msg }}
        </v-snackbar>
     
        <v-card class="pa-1" :img="require('../../assets/pho.jpg')">
            <v-card-title>
            <span class="headline" style="white-space: nowrap;">Formulaire d'inscription</span>
            </v-card-title>
            <v-list class="overflow-y-auto" >
            <v-card-text >
                <v-container v-if="!confirmed" >
                <v-form  ref="form" v-model="valid" lazy-validation  >
                    <v-row>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="Nom*"
                            :rules="allRule"
                            v-model="nom"
                            required></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="Prenom*"
                            :rules="allRule"
                            v-model="prenom"
                            required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="6" >
                            <v-text-field label="Email*"
                            :rules="emailRules"
                            v-model="email"
                            class="my-input"
                            onpaste="return false"
                            autocomplete="nope"
                            @keydown.space.prevent
                            required>
                            </v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="GSM"
                            type="tel"
                            v-model="tlf"
                            ></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6" >
                            <v-text-field label="Ville*"
                            :rules="allRule"
                            v-model="ville"
                            required></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6" >
                            <v-text-field label="Spécialité*"
                            :rules="allRule"
                            v-model="specialite"
                            required></v-text-field>
                        </v-col>
                    </v-row>
                    </v-form>
                </v-container>
                <v-container v-else>
                    <v-alert class="text" text>
                        Les laboratoires dermatologiques Pierre Fabre vous remercie de votre inscription.<br>
                        Prière de vérifier votre Email pour plus d’informations sur l’inscription.
                    </v-alert>
                </v-container>
                <v-alert text prominent type="error" v-show="formNotValid" icon="mdi-cloud-alert">
                    Formulaire non complet
                </v-alert><br>
            </v-card-text>
            </v-list>
            <v-card-actions>
                <v-btn
                    color="blue darken-1"
                    text
                    :disabled="!valid"
                    @click="validate"
                    v-if="!confirmed"
                    :loading="inscribtn"
                >
                    S'inscrire
                </v-btn>
            </v-card-actions>
        </v-card> -->

   
        <!-- <v-row>
            <v-col align="center">
                <h1 class="propsHead compHead" style="color:#2E77A3"  >
                    Inscription
                </h1>
                <v-divider></v-divider>
            </v-col>
        </v-row> -->
        <!-- <v-row>
            <v-container class="inscrir">
                
<div id="form_container">
			<div class="row">
				<div class="col-lg-5" >
					<div class="left_form">
						<figure><img src="img/reserve_bg.svg" alt=""></figure>
						<h2>Reservation</h2>
						<p>Tation argumentum et usu, dicit viderer evertitur te has. Eu dictas concludaturque usu, facete detracto patrioque an per, lucilius pertinacia eu vel.</p>
						<a href="#0" id="more_info" data-bs-toggle="modal" data-bs-target="#more-info"><i class="pe-7s-info"></i></a>
					</div>
				</div>
				<div class="col-lg-7">
                   
                    <v-form  ref="form" v-model="valid" lazy-validation  >
                        <v-row>	<h2>Reservation</h2>   </v-row>
                       
         <v-row>
           <v-col  cols="12" md="6" sm="4" class="la" > 
         <div class="form-icon">
             <span><i class="icon icon-user"></i></span>
         </div>
     </v-col> -->
    
					
     <!-- <v-col  cols="12" md="6" sm="4" class="la" >
         <div class="form-group">
             <input type="text" class="form-control item" v-model="nom" id="Nom" placeholder="Nom">
         </div>
     </v-col>

     <v-col  cols="12" md="6" sm="4" class="la" >
         <div class="form-group">
             <input type="password" class="form-control item" id="password" placeholder="Password">
         </div>
     </v-col>

     <v-col  cols="12" md="6" sm="4" class="la" >
         <div class="form-group">
             <input type="text" class="form-control item" id="email" placeholder="Email">
         </div>
     </v-col>

     <v-col  cols="12" md="6" sm="4" class="la" >
         <div class="form-group">
             <input type="text" class="form-control item" id="phone-number" placeholder="Phone Number">
         </div>
     </v-col>
         
     <v-col  cols="12" md="6" sm="4" class="la" >
         <div class="form-group">
             <input type="text" class="form-control item" id="birth-date" placeholder="Birth Date">
         </div>
     </v-col>

         <v-hover v-slot="{ hover }">
         <v-btn
             class="ma-2"
             dark
             x-large
             :style="{ 'background-color': hover ? 'red' : '#2962FF' }"
             :disabled="!valid"
             @click="validate"
             v-if="!confirmed"
             :loading="inscribtn"
             >
             Accept Terms
             </v-btn>
         </v-hover>
     </v-row>
     </v-form>
    </div>	
				</div>
			</div>
	







            </v-container> -->








<!-- 
            
        <v-container>
              
           
            <div class="registration-form">
                
         
        <v-form  ref="form" v-model="valid" lazy-validation  >
         
            <v-row>
                 <v-col  cols="12" md="6" sm="4" class="la" >
            <div class="form-icon">
                <span><i class="icon icon-user"></i></span>
            </div>
        </v-col> 

        <v-col  cols="12" md="6" sm="4" class="la" >
            <div class="form-group">
                <input type="text" class="form-control item" v-model="nom" id="Nom" placeholder="Nom">
            </div>
        </v-col>

        <v-col  cols="12" md="6" sm="4" class="la" >
            <div class="form-group">
                <input type="password" class="form-control item" id="password" placeholder="Password">
            </div>
        </v-col>

        <v-col  cols="12" md="6" sm="4" class="la" >
            <div class="form-group">
                <input type="text" class="form-control item" id="email" placeholder="Email">
            </div>
        </v-col>

        <v-col  cols="12" md="6" sm="4" class="la" >
            <div class="form-group">
                <input type="text" class="form-control item" id="phone-number" placeholder="Phone Number">
            </div>
        </v-col>
            
        <v-col  cols="12" md="6" sm="4" class="la" >
            <div class="form-group">
                <input type="text" class="form-control item" id="birth-date" placeholder="Birth Date">
            </div>
        </v-col>

            <v-hover v-slot="{ hover }">
            <v-btn
                class="ma-2"
                dark
                x-large
                :style="{ 'background-color': hover ? 'red' : '#2962FF' }"
                :disabled="!valid"
                @click="validate"
                v-if="!confirmed"
                :loading="inscribtn"
                >
                Accept Terms
                </v-btn>
            </v-hover>
        </v-row>
        </v-form>
   
        </div>
  

        </v-container> -->


        <div id="form_container">
            <br><br> 
            <div class="row">
           


                <div class=" reserv col-lg-5" >
                    <v-card  class="mx-auto pa-0 pb-0"
      elevation="4"
      max-width="900"
      rounded=""  >
                <div class="left_form">
                    <br>
                    <div class="img1" style="text-align: left"><img src="hash.png" width="100" 
     height="60"></div>
						<br><br> <h1>BIENVENUE</h1><div class="para2"> <p style="">à la plateforme de l'inscription au HTIC 2024 Fès by Morocco Sim </p> <br></div>
                        <div class="para">
					<p style="">
                        L'inscription se déroulera sur plusieurs étapes.
                          Restez attentifs à votre boite de réception d'emails afin de compléter votre inscription en bonne et due forme.</p>
						</div>
					</div>
                </v-card>
                </div>

                <div class="insc  col-lg-7" >
                    <v-snackbar  v-model="snackbar" :color="snackColor"  timeout="5000" top>
            {{ msg }}
        </v-snackbar>
        <v-card  class="mx-auto pa-4 pb-4"
      elevation="4"
      max-width="900"
      rounded=""  >
      <div class="right_form">
      <v-col align="center">
                <h1 class="propsHead compHead" style="color:#000000"  >
                  <b>PRÉ-INSCRIPTION</b>  
                </h1>
                <v-divider style="color:#953B27"></v-divider>
            </v-col>
          
        
            
                <!-- <v-img aspect-ratio="1"  class="img-responsive programvend" src="../../assets/avt.png"  ></v-img> -->
         
                <div class="col-lg-12">
                <v-container v-if="!confirmed" >
                   
                
         
                <!-- <v-form  ref="form" v-model="valid" lazy-validation  >
                 
                    <v-row>
                       
        
                <v-col  cols="12" md="6" sm="4" class="la" >
                    <div class="form-group">
                        <input type="text" class="form-control item" v-model="nom" id="Nom" placeholder="Nom" required>
                    </div>
                </v-col>
        
                <v-col  cols="12" md="6" sm="4" class="la" >
                    <div class="form-group">
                        <input type="text" class="form-control item " v-model="prenom" id="prenom"  placeholder="Prenom" required>
                    </div>
                </v-col>
        
                <v-col  cols="12" md="6" sm="4" class="la" >
                    <div class="form-group">
                        <input type="text" class="form-control item"  v-model="email" id="email" placeholder="Email" required>
                    </div>
                </v-col>
        
                <v-col  cols="12" md="6" sm="4" class="la" >
                    <div class="form-group">
                        <input type="text" class="form-control item"  v-model="tlf" id="Nom" placeholder="Gsm" required>
                    </div>
                </v-col>
                    
                <v-col  cols="12" md="6" sm="4" class="la" >
                    <div class="form-group">
                        <input type="text" class="form-control item" v-model="ville" id="birth-date" placeholder="Ville" required>
                    </div>
                </v-col>
                


                <v-col  cols="12" md="6" sm="4" class="la" >
                    <div class="form-group">
                        <select class="custom-select mr-sm-2" v-model="statut" id="statut" required >
       
                            <option value="" disabled selected>Statut</option>
                            <option value="Médecin">Médecin</option>
                            <option value="Médecin dentiste">Médecin dentiste</option>
                            <option value="Pharmacien">Pharmacien</option>                            
                            <option value="Infirmier">Infirmier</option>
                            <option value="Technicien ambulancie">Technicien ambulancier</option>
                            <option value="Professionnel de la santé">Professionnel de la santé</option>
                            <option value="Résident">Résident</option>
                            <option value="Interne">Interne</option>
                            <option value="Etudiant">Etudiant</option>
                            <option value="Biomédical">Biomédical</option>
                            <option value="Autre">Autre</option>
         </select>
                        
                    </div>
                </v-col>
        
                  
                </v-row>
                </v-form> -->
           
                
          
               <v-form  ref="form" v-model="valid" lazy-validation  >
                    <v-row>
                        <v-col  cols="12" md="6" sm="4" class="la" >
                            <v-text-field label="Nom"
                            :rules="allRule"
                            v-model="nom"
                            required  ></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="Prenom"
                            :rules="allRule"
                            v-model="prenom"
                            required></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" sm="6" >
                            <v-text-field label="Email"
                            :rules="emailRules"
                            v-model="email"
                            class="my-input"
                            onpaste="return false"
                            autocomplete="nope"
                            @keydown.space.prevent
                            required>
                            </v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6"  >
                            <v-text-field label="GSM"
                            type="tel"
                            v-model="tlf"
                            ></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6" >
                            <v-text-field label="Ville"
                            :rules="allRule"
                            v-model="ville"
                            required></v-text-field>
                        </v-col>
                        <v-col  cols="12" md="6" sm="6" >
                            <v-select
    v-model="statut"
    :items="states"
    :rules="allRule"
    label="Statut"
    required
    
    
  ></v-select>
 
                            <!-- <v-text-field label="Spécialité"
                            :rules="allRule"
                            v-model="specialite"
                            required></v-text-field> -->
                        </v-col>
                    </v-row>
                    </v-form> 
                </v-container>
                <v-container v-else>
                    <br><br>
                    <v-alert class="text" text>
                        HTIC 2024 by Morocco Sim vous remercie de votre pré-inscription.<br>
                        Prière de vérifier votre e-mail pour plus d'informations sur les modalités d'inscription.
                    </v-alert>
                    <br><br> <br><br>
                </v-container>
                <!-- <v-alert text prominent type="error" v-show="formNotValid" icon="mdi-cloud-alert">
                    Formulaire non complet
                </v-alert><br> -->
           
   
          <div class="envoyer">
                <v-hover v-slot="{ hover }">
                    <v-btn 
                        class="ma-2"
                        dark
                        x-large
                        :style="{ 'background-color': hover ? '#E89A06' : '#953B27' }"
                        :disabled="!valid"
                        @click="validate"
                        v-if="!confirmed"
                        :loading="inscribtn"
                        >
                        S'inscrire
                        </v-btn>
                    </v-hover>
                </div>
            </div>
        
            
        </div>
        </v-card>
    </div>
  
</div>
</div>
    
   
    </v-container>
</template>
<script>
export default {
   data () {
      return {
        inscribtn:false,
        email:'',
        emailConferm:'',
        nom:'',
        prenom:'',
        ville:'',
        tlf:'',
        statut:'',
        valid: true,
        dialog: false,
        confirmed: false,
        error:false,
        snackbar:false,
        snackColor:'',
        msg:'',
        formNotValid:false,
        states: [
    'Médecin',
    'Médecin dentiste',
    'Pharmacien',
    'Infirmier',
    'Technicien ambulancier',
    'Professionnel de la santé',
    'Résident',
    'Interne',
    'Médecin',
    'Etudiant',
    'Biomédical',
    'Autre',
    ],
        emailRules: [
            v => !!v || 'E-mail est requis',
            v => /.+@.+/.test(v) || 'L\'email doit être valide',
        ],

        allRule: [v => !!v || 'Ce champ est requis',],
        domaineRule: [(v) => !!v || "Item is required"],
        select: { name: 'Morocco', code: 'MA' },
        fonctionsList: [
            'Professeur',
            'Spécialiste secteur public',
            'Spécialiste secteur privé',
            'Résident/interne',
            'Infirmier'
        ],
      }
    },
    mounted() {
     
    },
    methods: {
        validate () {
            if(this.$refs.form.validate()){
                this.inscribtn = true
                this.email = this.email.toLowerCase()
                this.axios({
                    method: 'post',
                    url: 'https://htic2024v.hashtagsante.com/api/inscrit',
                    data: {
                        email:this.email ,
                        name:this.nom,
                        prenom:this.prenom,
                        tlf:this.tlf,
                        ville:this.ville,
                        statut:this.statut,
                    }
                }).then((res)=>{
                    console.log(this.confirmed)
                    if(res.status !='200'){
                        this.snackMsg(res.data)
                        this.error=false
                        this.inscribtn=false
                        console.log("im in if")
                        console.log(res)
                    }
                    else{
                        this.clear()             
                        this.confirmed = true
                        this.error=false
                        this.inscribtn=false
                        console.log("im in else")
                        console.log(res.data)
                    }
                }, err =>{
                    if(err){
                    this.emailError('Cet email déjà inscrit')
                    console.log(err)
                    this.inscribtn=false
                    }
                });
            }
            else{
                this.formNotValid = true
            }
            console.log(this.nom)
            console.log(this.prenom)
            

        },
        clear(){
            this.email = '',
            this.emailConferm='',
            this.nom = '' , 
            this.prenom = '' , 
            this.ville = '' , 
            this.statut= '',
            this.tlf = '' ,
            this.formNotValid = false
        },
        detectFonction(){
            if(this.statut === "Infirmier" || this.statut === "Résident/interne"){
                this.session = ''
                return true
            }
            else{
                return false
            }
        },
        snackMsg(msg){
            msg == "succes" ? this.snackColor = "green" : this.snackColor = "red"
            msg == "succes" ? this.msg = " Cher Docteur,\nVous êtes bien inscrit, veuillez vérifier votre boite Email " : this.msg = msg 
            this.snackbar = true
        },
        emailError(msg){
            this.snackbar = true
            this.snackColor = "red"
            this.msg =  msg 
        }
    },
  }
</script>
<style>
.para{
    text-align: justify;
  text-justify: inter-word;
  padding-left: 20px;
  padding-right: 20px;
  font-size:1.25vw
  
}
.para2{
    
 
  padding-left: 20px;
  padding-right: 20px;
  font-size:1.25vw
  
}

.envoyer{
    display: flex;
  justify-content: center;
  align-items: center;
}
.insc{
    min-height: 497px;
}
.img1{
margin-left: 25px;    


}


.reserv{
    margin-right: -26px;
   
}
.inscrire{
    background-color: #5691ff;
}

.right_form{
    min-height: 465px;

}
.left_form {
	background-color: #953B27;
	min-height: 497px;
	color: #fff;
	text-align: center;
	padding: 0px 0px 0px 0px;
	box-sizing: border-box;
	position: relative;
}

.left_form h2 {
	font-weight: 300;
	color: #fff;
	margin: 15px 0 0 0;
	padding: 0;
	font-size: 36px;
	margin-bottom: 15px;
}

.left_form p {
	font-size: 16px;
}

.registration-form{
	padding: 50px 0;
}

.registration-form form{
    background-color: #c2c0c0;
    max-width: 600px;
    margin: auto;
    padding: 50px 70px;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
}

.registration-form .form-icon{
	text-align: left;
    background-color: #5891ff;
    border-radius: 50%;
    font-size: 40px;
    color: white;
    width: 100px;
    height: 100px;
    margin: auto;
    margin-bottom: 50px;
    line-height: 100px;
}

.registration-form .item{
	border-radius: 10px;
    margin-bottom: 25px;
    padding: 30px 25px;
}

.registration-form .create-account{
    border-radius: 30px;
    padding: 10px 20px;
    font-size: 18px;
    font-weight: bold;
    background-color: #5791ff;
    border: none;
    color: white;
    margin-top: 20px;
}

.registration-form .social-media{
    max-width: 600px;
    background-color: #fff;
    margin: auto;
    padding: 35px 0;
    text-align: center;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    color: #9fadca;
    border-top: 1px solid #dee9ff;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
}

.registration-form .social-icons{
    margin-top: 30px;
    margin-bottom: 16px;
}

.registration-form .social-icons a{
    font-size: 23px;
    margin: 0 3px;
    color: #5691ff;
    border: 1px solid;
    border-radius: 50%;
    width: 45px;
    display: inline-block;
    height: 45px;
    text-align: center;
    background-color: #fff;
    line-height: 45px;
}

.registration-form .social-icons a:hover{
    text-decoration: none;
    opacity: 0.6;
}

@media (max-width: 576px) {
    .reserv{
    margin-right: 0px;
    width: 500px;
}
    .registration-form form{
        padding: 50px 20px;
    }

    .registration-form .form-icon{
        width: 70px;
        height: 70px;
        font-size: 30px;
        line-height: 70px;
    }
}
h1{
 font-family: 'Montserrat';
}
.inscrireInfo{
    font-size:1.5em
}
@media (max-width: 480px) {
    .reserv{
    margin-right: 0px;
    width: 500px;
}
}
@media all and (max-width: 768px) {
    .reserv{
    margin-right: 0px;
    width: 500px;
}
  .btn{
    font-size:10px
    }
    .insbtn{
        font-size:1em
    }
    .textInsc{
        font-size:10px
    } 
}



.my-input input{
  text-transform: lowercase
}

.welcome_text{
  font-size: 1.5em;
}    
.btn{
    background-color: #FBB400 !important;
    border-radius: 10px;
}
.insbtn{
        font-size:30px

    }
.textInsc{
    font-size:20px
}  

.ps {
  height: 400px;
}

@media (max-width: 480px) {
    .insbtn{
        font-size:15px
    }
    .reserv{
    margin-right: 0px;
    width: 500px;
}
}
</style>