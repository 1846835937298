<template>
  <v-app id="app">
    <div class="ay"></div>
    <router-view></router-view>
  </v-app>
</template>

<script>

export default {

};
</script>
<style >
.ay{
  background-color: aquamarine;
}
</style>